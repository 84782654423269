<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-csear-customers' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Customers</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>{{ customer.full_name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.customerDialog.open(customer)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Basic Details</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.customerDialog.open(customer)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>

            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ customer.email }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ customer.phone }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td style="padding-top: 13px; padding-bottom: 13px">
                    <div>{{ customer.address_line_1 }}</div>
                    <div>{{ customer.address_line_2 }}</div>
                    <div>{{ customer.address_company }}</div>
                    <div>{{ customer.address_city }}</div>
                    <div>{{ customer.address_postcode }}</div>
                    <div>{{ customer.address_county }}</div>
                    <div>{{ customer.address_country }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Supporter Membership</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-divider></v-divider>
            <v-simple-table v-if="customer.memberships.length > 0">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Expiry Date</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="membership in customer.memberships"
                  v-bind:key="membership.id"
                >
                  <td>
                    <v-chip
                      v-if="
                        new Date().toISOString().substring(0, 10) >
                        membership.valid_to
                      "
                      small
                      label
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                    <v-chip
                      v-else
                      small
                      label
                      :color="getSeasonPassStatusColor(membership.status)"
                      >{{ getSeasonPassStatusLabel(membership.status) }}</v-chip
                    >
                  </td>
                  <td>{{ membership.formatted_dates.valid_to }}</td>
                  <td class="text-right">
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          :to="{
                            name: 'module-csear-tickets-individual',
                            params: { ticketId: membership.id },
                          }"
                        >
                          <v-icon x-small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip> -->
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-text v-else> No Supporter Memberships </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <customer-dialog ref="customerDialog" />
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog.vue";
import { csear } from "../../mixins";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  mixins: [csear],

  components: {
    CustomerDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          to: { name: "module-csear-customers" },
          exact: true,
        },
        {
          text: "Customer",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.getters["csear/customersStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.customer.full_name,
      disabled: true,
    });
  },
};
</script>
